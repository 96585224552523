import React, { Fragment } from "react";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { Popover, Transition, Menu } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import useAccess from "hooks/use-access";
import { useUser } from "@entities/user/user-queries";
import { useLogout } from "@hooks/use-auth";

interface ISubMenuItem {
  icon: IconName;
  label: string;
  link: string;
  description?: string;
  onlyAdmin?: boolean;
}
interface IMenuItem {
  label: string;
  subMenus: ISubMenuItem[];
}

const MenuItems: IMenuItem[] = [
  {
    label: "ร้านค้า",
    subMenus: [
      {
        icon: "plus",
        label: "เพิ่มร้านค้า",
        link: "/customers/create/",
        description: "",
      },
      {
        icon: "map-marked",
        label: "แผนที่ร้านค้า",
        link: "/customers/map/",
        description: "แผนที่รวมร้านค้าทุกประเภท",
      },
      {
        icon: "store",
        label: "จัดการร้านค้า",
        link: "/customers/",
        description: "จัดการข้อมูลและดูสถิติตามร้านค้า",
      },
    ],
  },
  {
    label: "การขาย",
    subMenus: [
      {
        icon: "file-invoice-dollar",
        label: "ใบฝากขาย",
        link: "/bills/consignment-invoice/",
        description: "",
      },
      {
        icon: "file-invoice-dollar",
        label: "บิลเงินสด",
        link: "/bills/cash-invoice/",
      },
    ],
  },
  {
    label: "คลังสินค้า",
    subMenus: [
      {
        icon: "truck",
        label: "จัดการคลังสินค้า",
        link: "/stocks/",
        description: "ดูข้อมูลและสต้อกสินค้าภายในคลัง",
      },
      {
        icon: "truck",
        label: "จัดการคลังสินค้าสำหรับแอดมิน",
        link: "/settings/stocks/",
        description: "ดูข้อมูลและสต้อกสินค้าภายในคลัง",
        onlyAdmin: true,
      },
    ],
  },
  {
    label: "สินค้า",
    subMenus: [
      {
        icon: "shopping-bag",
        label: "จัดการข้อมูลสินค้า",
        link: "/products/",
        description: "จัดการข้อมูลสินค้า, ราคา, รูปภาพ",
      },
    ],
  },
  {
    label: "รายงาน",
    subMenus: [
      {
        icon: "file-invoice",
        label: "รายงานประจำวัน",
        link: "/reports/daily-report/",
      },
      {
        icon: "file-invoice",
        label: "รายงานสต้อกการ์ด",
        link: "/reports/stock-card-report/",
      },
      {
        icon: "file-invoice",
        label: "รายงานร้านขายฝากที่ขายดี",
        link: "/reports/most-paid-consignment-buyers/",
      },
      {
        icon: "file-invoice",
        label: "รายงานร้านเงินสดที่ขายดี",
        link: "/reports/most-paid-cash-buyers/",
      },
      {
        icon: "file-invoice",
        label: "รายงานสินค้าฝากขายที่ขายดี",
        link: "/reports/best-seller-consignment-items-report/",
      },
      {
        icon: "file-invoice",
        label: "รายงานสินค้าเงินสดที่ขายดี",
        link: "/reports/best-seller-cash-items-report/",
      },
      {
        icon: "file-invoice",
        label: "รายงานมูลค่ารวมร้านฝากขาย",
        link: "/reports/total-consignment-stores-value/",
      },
      {
        icon: "file-invoice",
        label: "รายงานสรุปคลังสินค้าในระบบ",
        link: "/reports/total-stock-value/",
      },
      {
        icon: "file-invoice",
        label: "รายงานทั้งหมด",
        link: "/reports/",
      },
    ],
  },
];

const filterMenusByRole = (isAdmin: boolean) => (subMenu: ISubMenuItem) => {
  if (subMenu.onlyAdmin) {
    return isAdmin;
  }
  return true;
};

const Navbar: React.FC = () => {
  const handleLogout = useLogout();
  const userQuery = useUser();
  const { hasRole } = useAccess();

  const isAdmin = hasRole("admin");
  const user = userQuery.data;
  return (
    <nav>
      <Popover className="relative bg-gray-800 z-2000">
        {({ open }) => (
          <>
            <div className="px-4 mx-auto">
              <div className="relative flex items-center justify-between h-14">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  <Popover.Button className="inline-flex items-center justify-center p-2 text-white bg-gray-700 rounded-md hover:text-gray-100 hover:bg-gray-600 focus:outline-none">
                    <span className="sr-only">Open menu</span>
                    <FontAwesomeIcon
                      icon={["fas", "bars"]}
                      aria-hidden="true"
                    />
                  </Popover.Button>
                </div>
                <div className="flex items-center justify-center flex-1 sm:items-stretch sm:justify-start">
                  <NavLink
                    to="/"
                    className="flex items-center text-white cursor-pointer text-md"
                  >
                    JP Office<sup>places</sup>
                  </NavLink>
                  <div className="justify-center flex-1 hidden sm:flex">
                    <Popover.Group as="nav" className="flex justify-center">
                      {user &&
                        MenuItems.map((menu) => (
                          <Popover className="relative" key={menu.label}>
                            {({ open }) => (
                              <>
                                <Popover.Button
                                  className={`
                               ${
                                 open
                                   ? "text-white bg-gray-700"
                                   : "text-gray-300"
                               }
                               group p-2 mr-2 text-sm leading-4 rounded-md hover:text-white hover:bg-gray-700  inline-flex
                               `}
                                >
                                  <span>{menu.label}</span>
                                  <FontAwesomeIcon
                                    icon={["fas", "chevron-down"]}
                                    className={`
                                   ${
                                     open
                                       ? "text-white bg-gray-700"
                                       : "text-gray-300"
                                   }
                                  ml-1 h-4 w-4 group-hover:text-white
                                 `}
                                    aria-hidden="true"
                                  />
                                </Popover.Button>
                                <Transition
                                  show={open}
                                  as={Fragment}
                                  enter="transition ease-out duration-200"
                                  enterFrom="opacity-0 translate-y-1"
                                  enterTo="opacity-100 translate-y-0"
                                  leave="transition ease-in duration-150"
                                  leaveFrom="opacity-100 translate-y-0"
                                  leaveTo="opacity-0 translate-y-1"
                                >
                                  <Popover.Panel
                                    static
                                    className="absolute z-10 w-screen max-w-xs px-2 mt-2 -ml-4 transform sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                                  >
                                    <div className="overflow-hidden rounded shadow ring-1 ring-black ring-opacity-5">
                                      <div className="relative p-2 bg-white">
                                        {menu.subMenus
                                          .filter(filterMenusByRole(isAdmin))
                                          .map((subMenu) => (
                                            <NavLink
                                              key={subMenu.label}
                                              to={subMenu.link}
                                              className={({ isActive }) =>
                                                "flex items-center p-2 mb-1 rounded-lg last:mb-0 hover:bg-gray-100" +
                                                (isActive
                                                  ? " text-white bg-gray-100"
                                                  : "")
                                              }
                                              end
                                            >
                                              <FontAwesomeIcon
                                                icon={["fas", subMenu.icon]}
                                                className="flex-shrink-0 w-4 h-4 text-gray-600"
                                                aria-hidden="true"
                                              />
                                              <div className="ml-2">
                                                <p className="text-sm font-medium text-gray-900">
                                                  {subMenu.label}
                                                </p>
                                                <p className="text-xs text-gray-500">
                                                  {subMenu.description}
                                                </p>
                                              </div>
                                            </NavLink>
                                          ))}
                                      </div>
                                    </div>
                                  </Popover.Panel>
                                </Transition>
                              </>
                            )}
                          </Popover>
                        ))}
                    </Popover.Group>
                  </div>
                </div>
                {/* User Profile */}
                {!user ? (
                  <div></div>
                ) : (
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-8 sm:pr-0">
                    <div className="relative ml-4">
                      <Menu>
                        <Menu.Button className="flex text-sm transition duration-150 ease-in-out">
                          <div className="flex items-center justify-center w-8 h-8 text-black bg-gray-200 rounded-full">
                            {user?.username.slice(0, 2).toUpperCase()}
                          </div>
                        </Menu.Button>
                        <Menu.Items className="absolute right-0 w-48 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            <span
                              className={`group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            >
                              {/* TODO: only admin user, not office and regular one can use it */}
                              {isAdmin ? "ผู้ดูแล 👨🏻‍💼" : "ผู้ใช้"}:{" "}
                              {user?.username || "-"}
                            </span>
                          </Menu.Item>
                          {isAdmin && (
                            <Menu.Item>
                              {({ active }) => (
                                <NavLink
                                  className={`${
                                    active ? "bg-gray-700 text-white" : ""
                                  } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                  to="/settings/users"
                                >
                                  จัดการผู้ใช้
                                </NavLink>
                              )}
                            </Menu.Item>
                          )}

                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={`${
                                  active ? "bg-gray-700 text-white" : ""
                                } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                onClick={() => handleLogout()}
                              >
                                ออกจากระบบ
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Menu>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="absolute inset-x-0 top-0 p-2 transition origin-top-right transform z-2000 md:hidden"
              >
                <div className="bg-gray-800 rounded shadow">
                  <div className="p-4">
                    <div className="flex items-center justify-end">
                      <Popover.Button className="inline-flex items-center justify-center p-2 text-white bg-gray-700 rounded-md hover:text-gray-100 hover:bg-gray-600 focus:outline-none">
                        <span className="sr-only">Close menu</span>
                        <FontAwesomeIcon
                          icon={["fas", "times"]}
                          aria-hidden="true"
                        />
                      </Popover.Button>
                    </div>
                    <div className="mt-4">
                      <nav className="">
                        {user &&
                          MenuItems.map((menu) => (
                            <div className="mb-2" key={menu.label}>
                              <h6 className="block p-2 text-xs font-bold text-gray-400">
                                {menu.label}
                              </h6>
                              {menu.subMenus
                                .filter(filterMenusByRole(isAdmin))
                                .map((subMenu) => (
                                  <NavLink
                                    key={subMenu.label}
                                    to={subMenu.link}
                                    className={({ isActive }) =>
                                      "flex items-center p-2 text-white rounded-md hover:bg-gray-700" +
                                      (isActive
                                        ? " text-white bg-gray-900"
                                        : "")
                                    }
                                    end
                                  >
                                    <FontAwesomeIcon
                                      icon={["fas", subMenu.icon]}
                                      className="flex-shrink-0 w-4 h-4 text-white"
                                      aria-hidden="true"
                                    />
                                    <span className="ml-1 text-sm text-white">
                                      {subMenu.label}
                                    </span>
                                  </NavLink>
                                ))}
                            </div>
                          ))}
                      </nav>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </nav>
  );
};

export default Navbar;
