import { Config } from "./config.development";

const config: Config = {
  apiURL: "https://app.jpaccessory.co.th",
  nextGenApiURL: "https://fresh.jpaccessory.co.th",
  developmentMode: false,
  sentryDNS:
    "https://e5821a6111844f3f8fcd0c02a46f1c86@o383965.ingest.sentry.io/5266618",
  features: {
    trade_route: false,
    customer_images: true,
    bill_images: false,
    daily_report: true,
    stock_summary_report: false,
    customer_stock_summary_report: false,
    add_item_with_pics: true,
  },
};

export default config;
