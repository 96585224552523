import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  Observable,
} from "@apollo/client";
import config from "@config/index";
import { setContext } from "@apollo/client/link/context";
import store from "@libs/localStorage";
import axios from "axios";
import queryClient from "@libs/queryClient";
import { onError } from "@apollo/client/link/error";

const httpLink = new HttpLink({
  uri: config.nextGenApiURL + "/graphql",
});

const authLink = setContext((_, { headers }) => {
  const token = store.getToken().access;

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const promiseToObservable = (promise: Promise<any>) => {
  return new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) {
          return;
        }

        subscriber.next(value);
        subscriber.complete();
      },
      (err) => {
        subscriber.error(err);
      }
    );
  });
};

// TODO: refresh token won't work well but we have /me that calls always, so if refresh, mostly works
const refreshAccessTokenLink = onError(
  ({ networkError, operation, forward }) => {
    if (!networkError) return;
    console.log(`[Network error]: ${networkError}`);

    if (![401, 403, 500].includes((networkError as any).response.status)) {
      return;
    }

    const refreshToken = store.getToken().refresh;
    if (refreshToken === null) {
      store.removeToken();
      queryClient.removeQueries("me");
      window.location.replace("/login");
      return;
    }

    const promise = axios
      .post(
        "/accounts/token/refresh/",
        {
          refresh: refreshToken,
        },
        {
          baseURL: config.apiURL,
        }
      )
      .then((resp) => {
        const { access } = resp.data;
        store.setToken({ access });
        console.log("refreshed token");
      })
      .catch(() => {
        store.removeToken();
        queryClient.removeQueries("me");
        window.location.replace("/login");
      });

    const oldHeaders = operation.getContext().headers;
    return promiseToObservable(promise).flatMap((newToken) => {
      operation.setContext({
        headers: {
          ...oldHeaders,
          authorization: newToken,
        },
      });

      return forward(operation);
    });
  }
);

const operationLink = new ApolloLink((operation, forward) => {
  const context = operation.getContext();
  const uri = context.uri || httpLink.options.uri;

  operation.setContext({
    uri: `${uri}${uri.includes("?") ? "&" : "?"}operation=${
      operation.operationName
    }`,
  });

  return forward(operation);
});

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      products_productimage: {
        merge: true,
      },
    },
  }),
  link: ApolloLink.from([
    refreshAccessTokenLink,
    authLink,
    operationLink,
    httpLink,
  ]),
});
