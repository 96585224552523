import api from "@libs/axios";
import store from "@libs/localStorage";
import { message } from "antd";
import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

type PostLoginParams = {
  username: string;
  password: string;
};

export const usePostLogin = () => {
  const navigate = useNavigate();

  return useMutation(
    async ({ username, password }: PostLoginParams): Promise<void> => {
      return api.post(`/accounts/token/`, { username, password }).then((resp) =>
        store.setToken({
          access: resp.data.access,
          refresh: resp.data.refresh,
        })
      );
    },
    {
      onSuccess: () => {
        message.success("เข้าสู่ระบบสำเร็จ");
        navigate("/", { replace: true });
      },
      onError: () => {
        store.removeToken();
        message.error("รหัสผ่านผิดพลาด");
        navigate("/login", { replace: true });
      },
    }
  );
};

export const useUser = (options: Record<string, any> = {}) =>
  useQuery<User, AxiosError>(
    "me",
    async () => api.get(`/accounts/me/`).then((resp) => resp.data),
    {
      retry: 1,
      staleTime: 60 * 1000 * 10, // 10 minute
      enabled: !!store.getToken().access,
      ...options,
    }
  );
