import axios, { AxiosResponse } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import store from "./localStorage";
import config from "config";
import { captureException } from "./sentry";
import queryClient from "./queryClient";

const axiosService = axios.create({
  baseURL: config.apiURL,
});

axiosService.interceptors.request.use(async (config) => {
  const token = store.getToken().access;

  if (!config.baseURL || !config.url) return;

  if (token !== null) {
    config.headers.Authorization = "Bearer " + token;
    console.debug(
      "[Request]",
      config.baseURL + config.url,
      JSON.stringify(token)
    );
  }
  return config;
});

axiosService.interceptors.response.use(
  (res) => {
    if (!res.config.baseURL || !res.config.url) return;

    console.debug(
      "[Response]",
      res.config.baseURL + res.config.url,
      res.status,
      res.data
    );
    return Promise.resolve(res);
  },
  (err) => {
    console.debug(
      "[Response]",
      err?.config.baseURL + err?.config.url,
      err?.response?.status || "no-status",
      err?.response?.data || "no-data"
    );
    return Promise.reject(err);
  }
);

const refreshAuthLogic = async (failedRequest: any) => {
  const refreshToken = store.getToken().refresh;

  if (refreshToken !== null) {
    return axios
      .post(
        "/accounts/token/refresh/",
        {
          refresh: refreshToken,
        },
        {
          baseURL: config.apiURL,
        }
      )
      .then((resp) => {
        const { access } = resp.data;
        failedRequest.response.config.headers.Authorization =
          "Bearer " + access;

        store.setToken({ access });
      })
      .catch(() => {
        store.removeToken();
        queryClient.removeQueries("me");
        window.location.replace("/login");
      });
  }
};

createAuthRefreshInterceptor(axiosService, refreshAuthLogic, {
  statusCodes: [401, 403, 500],
});

const handleResponse = (response: AxiosResponse) => {
  return Promise.resolve(response);
};

const catchError = (error: any) => {
  captureException(error);
  return Promise.reject(error);
};

const AxiosClient = {
  get: (path: string, params?: object) =>
    axiosService
      .request({
        url: path,
        method: "GET",
        params,
      })
      .then(handleResponse)
      .catch(catchError),
  post: (path: string, body?: object, headers?: object) =>
    axiosService
      .request({
        url: path,
        method: "POST",
        headers,
        data: body,
      })
      .then(handleResponse)
      .catch(catchError),
  put: (path: string, body?: object, headers?: object) =>
    axiosService
      .request({
        url: path,
        method: "PUT",
        headers,
        data: body,
      })
      .then(handleResponse)
      .catch(catchError),
  patch: (path: string, body?: object, headers?: object) =>
    axiosService
      .request({
        url: path,
        method: "PATCH",
        headers,
        data: body,
      })
      .then(handleResponse)
      .catch(catchError),
  delete: (path: string) =>
    axiosService.delete(path).then(handleResponse).catch(catchError),
};

export const NextGenAxiosService = axios.create({
  baseURL: config.nextGenApiURL,
});

NextGenAxiosService.interceptors.request.use(async (config) => {
  const token = store.getToken().access;

  if (!config.baseURL || !config.url) return;

  if (token !== null) {
    config.headers.Authorization = "Bearer " + token;
    console.debug(
      "[Request]",
      config.baseURL + config.url,
      JSON.stringify(token)
    );
  }
  return config;
});

createAuthRefreshInterceptor(NextGenAxiosService, refreshAuthLogic, {
  statusCodes: [401, 403, 500],
});

export default AxiosClient;
