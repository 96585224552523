import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { ModalProvider } from "components/modal/modal-context";
import { Route, Routes } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import Layout from "./components/layout";
import useAuth from "./hooks/use-auth";
import routes from "routes";
import "libs/fontAwesome";
import { withErrorBoundary } from "@libs/error-boundary";

const Auth: React.FC = () => {
  useAuth();
  return null;
};

const App: React.FC = () => {
  return (
    <>
      <ModalProvider>
        <HelmetProvider>
          {/* visible only in dev mode */}
          {!import.meta.env.PROD && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
          <Auth />
          <Layout>
            <Routes>
              {routes.map((route) => (
                <Route
                  element={
                    <React.Suspense fallback={<div />}>
                      <route.component />
                    </React.Suspense>
                  }
                  path={route.path}
                  key={route.path}
                />
              ))}
            </Routes>
          </Layout>
        </HelmetProvider>
      </ModalProvider>
    </>
  );
};

export default withErrorBoundary(App);
