import React, { Component } from "react";
import { captureException } from "@libs/sentry";
import { Result } from "antd";

interface Props {
  children: React.ReactElement;
}
interface State {
  hasError: boolean;
  error: Error;
}

interface Info {
  componentStack: string;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: new Error("initial") };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch = (error: Error, info: Info) => {
    captureException({
      ...error,
      reactErrorBoundaryStack: info.componentStack,
    });
  };

  render() {
    return this.state.hasError ? (
      // <ShowError msg={this.state.error.message} />
      <Result
        status="warning"
        title="ระบบเกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ"
        extra={this.state.error.message}
      />
    ) : (
      this.props.children
    );
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function withErrorBoundary<P extends {}>(
  WrappedComponent: React.ComponentType<P>
) {
  return function WithErrorBoundary(props: P) {
    return (
      <ErrorBoundary>
        <WrappedComponent {...props} />
      </ErrorBoundary>
    );
  };
}
