import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import queryClient from "@libs/queryClient";
import config from "config";
import App from "./App";

import "leaflet/dist/leaflet.css";
import "./assets/css/index.css";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "@libs/apollo";

if (!config.developmentMode) {
  Sentry.init({
    dsn: config.sentryDNS,
    integrations: [new BrowserTracing()],
    release: __GIT_COMMIT__,
    environment: config.developmentMode ? "local" : "production",
    tracesSampleRate: 0.001,
    ignoreErrors: [
      "Request failed with status code 403",
      /^Failed to fetch dynamically imported module:.*/g,
    ],
  });
}

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={apolloClient}>
        <Router>
          <App />
        </Router>
      </ApolloProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
